import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-hot-toast'
import api from '../../api'
import { downloadURI, tableToExcel } from '../../utils/export'
import { BC_STATUS_ID } from '../bc/constants'
import { EVENT_TYPE, wrapByEventLog } from '../eventLog/eventLogSlice'
import { STATUS_JOB } from '../updateProject/update'
import {
  REPORT_COVERAGE_BY_POINT_LIST, REPORT_COVERAGE_BY_SECTOR, REPORT_COVERAGE_BY_SIGNAL, REPORT_COVERAGE_BY_ZONE_LIST,
  REPORT_NAMES, REPORT_NEIGHBORS, REPORT_RESULT_KEY, REPORT_STATUS_KEY,
} from '../../constants/reports'
import {
  formCoverageByPointListReport, formCoverageBySectorReport, formCoverageBySignalReport, formCoverageByZoneListReport,
} from '../network/networkSlice'
import { alwaysTrue } from '../../utils/format'
import { addTaskType, setTaskTypeCompleted, setTaskTypeFailed, TASK_TYPES } from '../taskLog'
import { setTaskCompleted, setTaskFailed } from '../taskLog/taskLogSlice'
import { activeProjectId } from '../projects/projectsSlice'
import { selectFocusZones } from '../geo/geoSlice'

const allBusinessCases = createAsyncThunk(
  'reports/allBusinessCases',
  api.reports.allBusinessCases,
)

const financialIndicators = createAsyncThunk(
  'reports/financialIndicators',
  api.reports.financialIndicators,
)

const launchedSitesAndSectors = createAsyncThunk(
  'reports/launchedSitesAndSectors',
  api.reports.launchedSitesAndSectors,
)

const sendAsyncReportRequest = createAsyncThunk(
  'reports/asyncReportRequest',
  api.reports.asyncReportRequest,
)

const getAsyncReportResult = createAsyncThunk(
  'reports/asyncReportResult',
  api.reports.asyncReportResult,
)

const getBcCalculationDetails = createAsyncThunk(
  'reports/bcCalculationDetails',
  api.reports.bcCalculationDetails,
)

export const getBusinessCaseGroups = createAsyncThunk(
  'reports/businessCaseGroups',
  api.bc.getBusinessCaseGroups,
)

export const getProjectProperties = createAsyncThunk(
  'reports/getProjectProperties',
  api.projects.getProjectProperties,
)

const allBusinessCasesHeaders = {
  projectOwner: 'Author',
  bscgName: 'Group Case Name',
  bscName: 'Business Case Name',
  projectDescription: 'Project',
  access: 'Access',
  bscNetworkSiteName: 'Site Name',
  bscNetworkRbsNames: 'RBS',
  bscNetworkSectorsNames: 'Sectors',
  bsciCalcMethod: 'Calc Method',
  bsciVarNeighbors: 'Neighbors Sectors',
  bsciVarCapexConstruction: 'Capex Construction',
  bsciVarCapexRan: 'Capex Ran',
  bsciVarCapexOther: 'Capex Other',
  bsciVarCaseDuration: 'Case Duration, Years',
  bsciCaseType: 'Case Type',
  bsciSiteType: 'Site Type',
  bsciVarDateCapex: 'Start Capex Date',
  bsciVarDateRevenue: 'Start Revenue Date',
  bsciVarEdrpou: 'EDRPOU',
  bsciVarAssociationIncome: 'Association Income',
  bsciVarOpexRent: 'Opex Rent',
  bsciVarOpexUtilities: 'Opex Utilities',
  bsciVarOpexMaintenance: 'Opex Maintenance',
  bsciVarOpexOther: 'Other Opex',
  bsciVarRevenueAdditional: 'Additional Revenue',
  bsciVarRevenueTrend: 'Additional Revenue Trend',
  bsciVarTowerco: 'Towerco',
  bsciVarTowercoRent: 'Towerco Rent',
  bscComment: 'Comments',
  bsciVarCoefCanibData: 'Data Cannibalization Coef.',
  bsciVarCoefCanibVoiceTraf: 'Voice Cannibalization Coef.-Traf',
  bsciVarCoefCanibVoiceRev: 'Voice Cannibalization Coef.-Rev',
  bsciResultNpv: 'NPV, UAH',
  bsciResultRoi: 'ROI',
  bsciResultIrr: 'IRR',
  bsciResultPaybackPeriodYears: 'Payback Period, Years',
  bsciResultWac: 'WACC',
  bsciStatus: 'Status ID',
  bsciStatusName: 'Status',
}

const financialIndicatorsHeaders = {
  siteName: 'Site Name',
  bscgName: 'GBC Name',
  bscName: 'BC Name',
  bsciCalcMethod: 'Calculation Method',
  bsciStatusName: 'Status',
  bsciApprovedAt: 'Approval Date',
  bsciApprovedBy: 'Approval Author',
  siteLaunchDate: 'Launch Date',
  bsciResultNpv: 'NPV',
  bsciResultRoi: 'ROI',
  bsciResultIrr: 'IRR',
  bsciResultWacc: 'WACC',
  bsciResultPaybackPeriodYears: 'DPP',
}

const CUMULATED_DCF = 'cumulatedDcf'

const yearlyItemsHeaders = {
  capex: 'Capex',
  revenue: 'Revenue',
  opex: 'Opex',
  ebitda: 'EBITDA',
  netCashFlow: 'NetCashFlow',
  discountedCf: 'DCF',
  [CUMULATED_DCF]: 'Cumulated DCF',
}

const launchedSitesAndSectorsHeaders = {
  bscgName: 'Group BC',
  bscName: 'BC Name',
  siteName: 'Site Name',
  sectors: 'Sector Names',
  bscgOwner: 'BC Author',
  projectDescription: 'Project Name',
  bsciApprovedAt: 'Approval Date',
  bsciApprovedBy: 'Approval Author',
  siteLaunchDate: 'Launch Date',
}

const neighborsHeaders = {
  bscgNames: 'Group BC',
  bscNames: 'BC',
  newSiteName: 'New Site',
  newSectorName: 'New Sector',
  newSectorBand: 'New Sector Band',
  neighborSiteName: 'Neighbor Site',
  neighborSectorName: 'Neighbor Sector',
  neighborSectorBand: 'Neighbor Sector Band',
  distance: 'Distance',
  overlapIndexDistanceWeighted: 'Overlap Index Distance Weighted',
}

const dateTimeFields = [ 'bsciApprovedAt' ]

const dateFields = [ 'siteLaunchDate', 'bsciVarDateCapex', 'bsciVarDateRevenue' ]

const objectFields = [ 'bsciVarNeighbors' ]

const ALL_BUSINESS_CASES = 'All Business Cases'
const FINANCIAL_INDICATORS = 'Financial Indicators'
const LAUNCHED_SITES_AND_SECTORS = 'Launched Sites and Sectors'
const NEIGHBORS = 'Neighbors'
const LAUNCHED_SITES_FOR_NEIGHBORS = `${LAUNCHED_SITES_AND_SECTORS} information for ${NEIGHBORS} report`

const fieldValue = (item, key) => {
  let result = item[key]
  if (dateFields.includes(key)) {
    result = result && result !== '1970-01-01' ? new Date(result).toLocaleDateString() : ''
  }
  if (dateTimeFields.includes(key)) {
    result = result && result.slice && result.slice(0, 10) !== '1970-01-01' ? new Date(result).toLocaleString() : ''
  }
  if (objectFields.includes(key)) {
    // Do not add technologies info to the report
    result = result
      ? Object.values(result).reduce((res, techValue) => {
        res += Object.keys(techValue).reduce((agg, sector) => {
          if (techValue[sector]?.length > 0) {
            agg += `${sector}: ${techValue[sector]?.join(', ')}; `
          }
          return agg
        }, '')
        return res
      }, '').trim()
      : ''
  }
  return result
}

const initialState = {
  siteNames: [],
  checkMarks: [],
  resolve: null,
  businessCaseGroups: null,
  projectProperties: null,
  isLoading: false,
}

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setSiteNames: (state, action) => {
      state.siteNames = action.payload
      state.checkMarks = Array(action.payload.length).fill(false)
    },
    setCheckMark: (state, action) => {
      const { index, value } = action.payload
      state.checkMarks[index] = value
    },
    selectAll: (state) => {
      state.checkMarks = state.checkMarks.fill(true)
    },
    unselectAll: (state) => {
      state.checkMarks = state.checkMarks.fill(false)
    },
    setResolve: (state, action) => {
      state.resolve = action.payload
    },
    clear: (state) => {
      state.siteNames = []
      state.checkMarks = []
      state.resolve = null
    },
  },
  extraReducers: (builder) => builder
    .addCase(getBusinessCaseGroups.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getBusinessCaseGroups.fulfilled, (state, action) => {
      state.isLoading = false
      state.businessCaseGroups = action.payload
    })
    .addCase(getBusinessCaseGroups.rejected, (state) => {
      state.isLoading = false
    })
    .addCase(getProjectProperties.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getProjectProperties.fulfilled, (state, action) => {
      state.isLoading = false
      state.projectProperties = action.payload
    })
    .addCase(getProjectProperties.rejected, (state) => {
      state.isLoading = false
    }),
})

const { setSiteNames, clear } = reportsSlice.actions
export const { setCheckMark, setResolve, selectAll, unselectAll } = reportsSlice.actions

export const getAllBusinessCases = async (dispatch) => {
  const taskType = TASK_TYPES.allBusinessCasesReport
  try {
    dispatch(addTaskType(taskType))
    let res
    await dispatch(wrapByEventLog({
      type: EVENT_TYPE.generateReport,
      details: ALL_BUSINESS_CASES,
      action: async () => {
        const { payload = [] } = await dispatch(allBusinessCases())
        const headers = Object.values(allBusinessCasesHeaders)
        const keys = Object.keys(allBusinessCasesHeaders)
        const cells = payload.map((item) => keys.map((key) => fieldValue(item, key)))
        res = await tableToExcel(headers, cells, alwaysTrue, 'All Business Cases', 'All Business Cases.xlsx')
        return (res ? res + ', ' : '') + payload.length
      },
      extractor: (length) => `${ALL_BUSINESS_CASES}: ${length} items`,
      waitCursor: true,
    }))
    if (res) {
      toast.success(`The report generated with limitations:\n${res}`, { duration: 5000 })
    }
    dispatch(setTaskTypeCompleted(taskType))
  } catch (e) {
    toast.error(`An error cooured while generating the report:\n${e}`, { duration: 5000 })
    dispatch(setTaskTypeFailed(taskType))
  }
}

const roundToFixed = (value, precision = 2) => {
  if (!value) {
    return value
  }
  const factor = 10 ** precision
  return Math.round(value * factor) / factor
}

export const getFinancialIndicators = (businessCaseGroup) => (dispatch) => dispatch(wrapByEventLog({
  type: EVENT_TYPE.generateReport,
  details: businessCaseGroup.id
    ? `${FINANCIAL_INDICATORS} - ${businessCaseGroup.title}${businessCaseGroup.isOnlyValid ? ' (Valid only)' : ''}`
    : FINANCIAL_INDICATORS,
  action: async () => {
    const taskType = TASK_TYPES.financialIndicatorsReport
    let success = false
    try {
      dispatch(addTaskType(taskType))
      const { id: businessCaseGroupId, title } = businessCaseGroup
      const { payload: newPayload } = await dispatch(financialIndicators(businessCaseGroupId))

      const payload = newPayload?.rows || []

      const years = []
      payload.forEach((item) => {
        item.bsciResultFinancials?.yearlyItems?.forEach((yItem) => {
          const year = Number(yItem?.year)
          if (year && !years.includes(year)) {
            years.push(year)
          }
        })
      })
      const monthDates = []
      payload.forEach((item) => {
        item.bsciResultFinancials?.monthlyItems?.forEach((mItem) => {
          const date = mItem?.date
          // const month = date && moment(date, 'YYYY-MM-DD').startOf('month').toDate().toLocaleDateString()
          if (date && !monthDates.includes(date)) {
            monthDates.push(date)
          }
        })
      })
      years.sort((a, b) => a - b)
      monthDates.sort()
      const dateToMonth = (date) => {
        // moment(date, 'YYYY-MM-DD').startOf('month').toDate().toLocaleDateString()
        // Moment.js is too slow for this use case
        const arr = date.split('-')
        return `${arr[1]}/${arr[0]}`
      }
      const months = monthDates.reduce((agg, date) => {
        const monthStr = dateToMonth(date)
        if (!agg.includes(monthStr)) {
          agg.push(monthStr)
        }
        return agg
      }, [])
      const headers = [
        ...Object.values(financialIndicatorsHeaders),
        'BC Financials', ...years.map(() => ''), 'Total', '', ...months.map(() => '') ]
      const keys = Object.keys(financialIndicatorsHeaders)
      const emptyRow = keys.map(() => '')
      const cells = []
      payload.forEach((item) => {
        cells.push([
          ...keys.map((key) => fieldValue(item, key)), '',
          ...years, '', '',
          ...months,
        ])
        Object.entries(yearlyItemsHeaders).forEach(([ key, value ]) => {
          cells.push([
            ...emptyRow,
            value,
            ...years.map((year) => {
              const yItem = item.bsciResultFinancials?.yearlyItems?.find((y) => Number(y.year) === year)
              return roundToFixed(yItem?.[key]) ?? ''
            }),
            key === CUMULATED_DCF
              ? ''
              : roundToFixed(item.bsciResultFinancials
                ?.yearlyItems?.reduce((acc, yItem) => {
                  if (typeof yItem[key] === 'number') {
                    return (acc ?? 0) + yItem[key]
                  }
                  return acc
                }, null)) ?? '',
            '',
            ...months.map((month) => {
              const mItem = item.bsciResultFinancials?.monthlyItems?.find((m) => dateToMonth(m.date) === month)
              return roundToFixed(mItem?.[key]) ?? ''
            }),
          ])
        })
        cells.push([ ...emptyRow, '', ...years.map(() => ''), '', '', ...months.map(() => '') ])
      })
      cells.push([ ...emptyRow, '', ...years.map(() => ''), '', '', ...months.map(() => '') ])
      const sum = years.map((year) => ({
        year,
        ...Object.fromEntries(Object.keys(yearlyItemsHeaders).map((key) => [ key, null ])),
      }))
      const monthSum = months.map((month) => ({
        month,
        ...Object.fromEntries(Object.keys(yearlyItemsHeaders).map((key) => [ key, null ])),
      }))
      const notRejected = payload.filter((p) => p.bsciStatus !== BC_STATUS_ID.REJECTED)
      notRejected.forEach((item) => {
        item.bsciResultFinancials?.yearlyItems?.forEach((yItem) => {
          const y = sum.find((s) => Number(s.year) === Number(yItem.year))
          Object.keys(yearlyItemsHeaders).forEach((key) => {
            if (typeof yItem[key] === 'number') {
              y[key] = (y[key] ?? 0) + yItem[key]
            }
          })
        })
        item.bsciResultFinancials?.monthlyItems?.forEach((mItem) => {
          const m = monthSum.find((s) => s.month === dateToMonth(mItem.date)) // TODO correct check
          if (m) {
            Object.keys(yearlyItemsHeaders).forEach((key) => {
              if (typeof mItem[key] === 'number') {
                m[key] = (m[key] ?? 0) + mItem[key]
              }
            })
          }
        })
      })
      cells.push([
        'Total',
        ...(keys.slice(1).map((key) => {
          if (key === 'bsciResultPaybackPeriodYears') {
            return newPayload.pbp/* DPP */ || ''
          }
          const propName = key.replace('bsciResult', '').toLowerCase()
          return newPayload[propName] || ''
        })),
        '',
        ...years,
        '', '',
        ...months,
      ])
      Object.entries(yearlyItemsHeaders).forEach(([ key, value ]) => {
        cells.push([
          ...emptyRow,
          value,
          ...years.map((year) => {
            const yItem = sum.find((y) => Number(y.year) === year)
            return roundToFixed(yItem?.[key]) ?? ''
          }),
          key === CUMULATED_DCF
            ? ''
            : roundToFixed(sum.reduce((acc, yItem) => {
              if (typeof yItem[key] === 'number') {
                return (acc ?? 0) + yItem[key]
              }
              return acc
            }, null)) ?? '',
          '',
          ...months.map((month) => {
            const mItem = monthSum.find((m) => m.month === month)
            return roundToFixed(mItem?.[key]) ?? ''
          }),
        ])
      })
      cells.push([ ...emptyRow, '', ...years.map(() => ''), '' ])
      cells.push([ ...emptyRow, 'Total BC', newPayload?.totalBC, ...years.map(() => '') ])
      cells.push([ ...emptyRow, 'Approved BC', newPayload?.approvedBC, ...years.map(() => '') ])
      const sectionSize = 9 // Num of rows for one BC
      tableToExcel(
        headers, cells, alwaysTrue,
        'Financial Indicators', title ? `Financial Indicators (${title}).xlsx` : 'Financial Indicators.xlsx', sectionSize)
      success = true
      return payload.length
    } finally {
      if (success) {
        dispatch(setTaskTypeCompleted(taskType))
      } else {
        dispatch(setTaskTypeFailed(taskType))
      }
    }
  },
  extractor: (length) => `${businessCaseGroup.id
    ? `${FINANCIAL_INDICATORS} - ${businessCaseGroup.title}${businessCaseGroup.isOnlyValid ? ' (Valid only)' : ''}`
    : FINANCIAL_INDICATORS}: ${length} items`,
  waitCursor: true,
}))

export const getLaunchedSitesAndSectors = (dispatch) => dispatch(wrapByEventLog({
  type: EVENT_TYPE.generateReport,
  details: LAUNCHED_SITES_AND_SECTORS,
  action: async () => {
    const { payload = [] } = await dispatch(launchedSitesAndSectors())
    const headers = Object.values(launchedSitesAndSectorsHeaders)
    const keys = Object.keys(launchedSitesAndSectorsHeaders)
    const cells = payload.map((item) => keys.map((key) => fieldValue(item, key)))
    tableToExcel(headers, cells, alwaysTrue, 'Launched Sites And Sectors', 'Launched Sites And Sectors.xlsx')
    return payload.length
  },
  extractor: (length) => `${LAUNCHED_SITES_AND_SECTORS}: ${length} items`,
  waitCursor: true,
}))

export const getNeighbors = (dispatch) => dispatch(wrapByEventLog({
  type: EVENT_TYPE.generateReport,
  details: LAUNCHED_SITES_FOR_NEIGHBORS,
  action: async () => {
    const { payload } = await dispatch(launchedSitesAndSectors())
    const siteNames = [ ...new Set(payload.map(({ siteName }) => siteName)) ]
    dispatch(setSiteNames(siteNames.sort()))
    return siteNames.length
  },
  extractor: (length) => `${LAUNCHED_SITES_FOR_NEIGHBORS}: ${length} items`,
  waitCursor: true,
}))

export const requestNeighborsReport = async (dispatch, getState) => {
  const state = getState()
  const projectId = activeProjectId(state)
  const siteNames = state.reports.siteNames.filter((_, index) => state.reports.checkMarks[index])
  return dispatch(doRequestAsyncReport(REPORT_NEIGHBORS, { projectId, siteNames }))
}

export const requestCoverageByPointListReport = (pointList, period) => (dispatch, getState) => {
  const state = getState()
  const projectId = activeProjectId(state)
  return dispatch(doRequestAsyncReport(REPORT_COVERAGE_BY_POINT_LIST, { projectId, ...period, pointList }))
}

export const requestCoverageByZoneListReport = (dispatch, getState) => {
  const state = getState()
  const projectId = activeProjectId(state)
  const zoneIds = selectFocusZones(state.geo).map(({ uuid }) => uuid)
  return dispatch(doRequestAsyncReport(REPORT_COVERAGE_BY_ZONE_LIST, { projectId, zoneIds }))
}

export const formNeighborsReport = (payload) => (dispatch) => {
  const headers = Object.values(neighborsHeaders)
  const keys = Object.keys(neighborsHeaders)
  const emptyRow = keys.map(() => '')
  const cellsData = (payload.data || []).map((item) => keys.map((key) => fieldValue(item, key)))
  const cellsVoice = (payload.voice || []).map((item) => keys.map((key) => fieldValue(item, key)))
  tableToExcel(
    [ 'Data', ...emptyRow.slice(1) ],
    [
      headers,
      ...cellsData,
      emptyRow,
      [ 'Voice', ...emptyRow.slice(1) ],
      headers,
      ...cellsVoice,
    ],
    alwaysTrue,
    'Neighbourhoods List',
    'Neighbourhoods List.xlsx',
  )
  dispatch(clear())
  return cellsData.length + cellsVoice.length
}

export const doRequestAsyncReport = (type, properties) => async (dispatch) => {
  const result = await dispatch(wrapByEventLog({
    type: EVENT_TYPE.requestAsyncReport,
    details: REPORT_NAMES[type],
    action: () => dispatch(sendAsyncReportRequest({ type, properties })),
    waitCursor: true,
  }))
  const response = result?.payload
  console.info('Job started', response)
  if (response?.status === STATUS_JOB.NEW) {
    dispatch(addTaskType(TASK_TYPES.asyncReport, REPORT_NAMES[type], response?.id))
  }
  return response
}

export const doGetAsyncReportResult = (type, reportId) => async (dispatch) => {
  try {
    await dispatch(wrapByEventLog({
      type: EVENT_TYPE.getAsyncReportResult,
      details: REPORT_NAMES[type],
      action: async () => {
        const {
          payload: {
            [REPORT_RESULT_KEY]: result,
            [REPORT_STATUS_KEY]: status,
          } = {},
        } = await dispatch(getAsyncReportResult(reportId))

        let itemsCount = 0
        if (status === STATUS_JOB.FINISHED_SUCCESS) {
          switch (type) {
            case REPORT_NEIGHBORS: {
              itemsCount = dispatch(formNeighborsReport(result))
              break
            }
            case REPORT_COVERAGE_BY_SIGNAL: {
              itemsCount = dispatch(formCoverageBySignalReport(result))
              break
            }
            case REPORT_COVERAGE_BY_SECTOR: {
              itemsCount = dispatch(formCoverageBySectorReport(result))
              break
            }
            case REPORT_COVERAGE_BY_POINT_LIST: {
              itemsCount = dispatch(formCoverageByPointListReport(result))
              break
            }
            case REPORT_COVERAGE_BY_ZONE_LIST: {
              itemsCount = dispatch(formCoverageByZoneListReport(result))
              break
            }
            default: {
              throw new Error(`Unknown report type: ${type}`)
            }
          }
        } else {
          throw new Error(`Report "${REPORT_NAMES[type]}" Error: ${JSON.stringify(result)}`)
        }
        return itemsCount
      },
      extractor: (length) => `${REPORT_NAMES[type]}: ${length} items`,
      waitCursor: true,
    }))
    dispatch(setTaskCompleted(reportId))
  } catch (error) {
    dispatch(setTaskFailed(reportId))
    window.dispatchEvent(new ErrorEvent('resterror', { error, message: error.message }))
    throw error
  }
}

export const doSaveBCCalculationDetails = (type, notificationId, bcName) => async (dispatch) => {
  try {
    await dispatch(wrapByEventLog({
      type: EVENT_TYPE.saveBCCalculationDetails,
      details: REPORT_NAMES[type],
      action: async () => {
        const res = await dispatch(getBcCalculationDetails(notificationId))
        const data = res.payload
        const url = window.URL.createObjectURL(new Blob([ data ], { type: 'application/zip' }))
        downloadURI(url, `${bcName}.zip`)
      },
      extractor: () => `Calculation details for ${bcName} saved`,
      waitCursor: true,
    }))
  } catch (error) {
    window.dispatchEvent(new ErrorEvent('resterror', { error, message: error.message }))
    throw error
  }
}

export const selectSiteNames = (state) => state.reports.siteNames
export const selectCheckMarks = (state) => state.reports.checkMarks
export const selectResolve = (state) => state.reports.resolve
export const selectIsLoading = (state) => state.reports.isLoading
export const selectBusinessCaseGroups = (state) => state.reports.businessCaseGroups
export const selectProjectProperties = (state) => state.reports.projectProperties

export default reportsSlice.reducer
