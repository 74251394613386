import React, { useCallback, useEffect, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Spinner } from '@fluentui/react'
import { selectAdditionalPanel, selectTab, setAdditionalPanel, setTab } from '../../features/panel/panelSlice'
import Pivot, { FW_TABS_3 } from '../../components/common/Pivot'
import { IconButton, PrimaryButton, SecondaryButton } from '../common/Button'
import PanelSections from '../Panels/PanelSections'
import TextField from '../common/TextField'
import Checkbox from '../common/Checkbox'
import {
  addModifiedValues,
  clearModified,
  clearSummaryData,
  getBusinessCaseSummary,
  loadAllBCData,
  saveBusinessCases,
  selectBCFields,
  selectBCList,
  selectCreateBCModifiedValues, selectDifferencesData, selectDifferencesExpired,
  selectFinancialsData, selectFinancialsLoading, selectIsBCFieldEditable, selectSummaryData, selectSummaryLoading,
} from '../../features/bc/bcSlice'
import { findIndex } from '../../features/network/indexing'
import * as Constants from '../../features/bc/constants'
import { FullscreenTableIcon } from '../common/icons/names'
import Grid from '../common/Grid'
import { getCssVar } from '../../utils/css'
import { DATE_TIME_FORMAT } from '../Tables/utils'
import {
  inputsFieldIds, bcSummaryFieldsByTab, summaryFieldIds,
} from './BCSummaryPanelConstants'

import './BCSummaryPanel.css'

const BCSummaryPanel = ({ onClose, data, additionalPanelRef }) => {
  const dispatch = useDispatch()

  const loading = useSelector(selectSummaryLoading)
  const summaryData = useSelector(selectSummaryData)
  const modifiedValues = useSelector(selectCreateBCModifiedValues)
  const fields = useSelector(selectBCFields)
  const list = useSelector(selectBCList)
  const additionalPanel = useSelector(selectAdditionalPanel)

  const loadingFinancials = useSelector(selectFinancialsLoading)
  const financialsData = useSelector(selectFinancialsData)

  const differencesData = useSelector(selectDifferencesData)
  const differencesExpired = useSelector(selectDifferencesExpired)

  const isBCFieldEditableFunction = useSelector(selectIsBCFieldEditable)
  const isBCFieldEditable = useMemo(() => {
    return isBCFieldEditableFunction(fields, list?.getList(), 'businessCases', true)
  }, [ fields, list, isBCFieldEditableFunction ])

  const id = useMemo(() => {
    return summaryData && summaryData[Constants.BC_ID_FIELD]
  }, [ summaryData ])
  const isModified = useMemo(() => {
    return !!modifiedValues
  }, [ modifiedValues ])

  const DifferencesMessage = useMemo(() => {
    let message = 'No check has been performed. To view the differences, click the "Check correspondence" button'
    if (differencesExpired) {
      message = `Business Case is no longer relevant.
        More than 3 months have passed since the launch of its network items.`
    }
    return <div style={{ flex: 1, marginTop: 20, padding: '0 20px' }}>
      {message}
    </div>
  }, [ differencesExpired ])

  const businessCaseId = useMemo(() => {
    return data && JSON.parse(data)
  }, [ data ])

  useEffect(() => {
    if (!businessCaseId) {
      return
    }
    dispatch(getBusinessCaseSummary(businessCaseId))
  }, [ dispatch, businessCaseId ])

  const handleOnEditField = useCallback((fieldName, value) => {
    dispatch(addModifiedValues({ [id]: { [fieldName]: value } }))
  }, [ dispatch, id ])

  const tab = useSelector(selectTab) || 'summary'

  const handleLinkClick = useCallback((item) => {
    dispatch(setTab(item.props.itemKey))
    dispatch(setAdditionalPanel(null))
  }, [ dispatch ])

  const handleOnClose = useCallback(() => {
    dispatch(clearSummaryData())
    dispatch(clearModified())
    if (onClose) {
      onClose()
    }
  }, [ dispatch, onClose ])

  const handleOnSave = useCallback(async () => {
    const gcId = summaryData[Constants.BC_GROUP_ID_FIELD]
    const res = await dispatch(saveBusinessCases({ gcId, isSummary: true }))
    if (!res?.error) {
      await dispatch(loadAllBCData())
      await dispatch(clearModified())
      dispatch(clearSummaryData())
      if (onClose) {
        onClose()
      }
    }
  }, [ dispatch, summaryData, onClose ])

  const handleOnEdit = useCallback((fieldName) => (event) => {
    const value = event.target.value
    handleOnEditField(fieldName, value)
  }, [ handleOnEditField ])

  const renderInput = useCallback((id, label, description, value, onChange) => (
    <TextField
      key={id}
      label={label}
      description={description}
      maxLength={250}
      defaultValue={value}
      readOnly={!onChange}
      onChange={onChange}
    />
  ), [])

  const renderCheckBox = useCallback((id, label, value) => (
    <Checkbox
      key={id}
      id={id}
      label={label}
      checked={value}
      disabled={true}
    />
  ), [])

  const getItems = useCallback((fieldIds) => (
    Object.keys(fieldIds).map((key) => {
      const status = summaryData && summaryData[Constants.BC_STATUS_ID_FIELD]
      const preparedKey = key.replace(' ', '').toLowerCase()
      const sectionId = `${preparedKey}.id`
      return {
        key: sectionId,
        id: sectionId,
        title: key,
        Component: () => (
          <>
            {fieldIds[key].map((id) => {
              let field = bcSummaryFieldsByTab[preparedKey]?.[status]?.[id]
              if (!field) {
                field = bcSummaryFieldsByTab[preparedKey]?.[id]
              }
              const fieldIdx = findIndex(fields, id)
              if (!field) {
                field = fields[fieldIdx]
              }
              if (!field || field?.hiddenInSummary) {
                return null
              }

              const isCheckbox = field?.type === 'boolean'
              const editable = isBCFieldEditable(fieldIdx, summaryData?.row)
              if (editable) {
                return renderInput(
                  id, field?.label, field?.description,
                  summaryData && summaryData[id],
                  handleOnEdit(id))
              }
              const value = summaryData && (field?.type === 'datetime' && summaryData[id]
                ? moment(new Date(summaryData[id])).format(DATE_TIME_FORMAT)
                : summaryData[id])
              return isCheckbox
                ? renderCheckBox(id, field?.label, value)
                : renderInput(id, field?.label, field?.description, value)
            }).filter(Boolean)}
          </>
        ),
      }
    })
  ), [ fields, summaryData, handleOnEdit, renderCheckBox, renderInput, isBCFieldEditable ])

  const openAdditionalPanel = useCallback((event) => {
    event.stopPropagation()
    dispatch(setAdditionalPanel('Financials'))
  }, [ dispatch ])

  // eslint-disable-next-line react/display-name
  const SummaryComponent = useMemo(() => () => {
    const { data, fields } = financialsData

    const filteredIndexes = []
    const visibleFields = additionalPanel
      ? fields
      : fields.filter((field, index) => {
        const result = !(parseInt(field.label) > 2000)
        if (result) {
          filteredIndexes.push(index)
        }
        return result
      })
    const visibleData = additionalPanel
      ? data
      : data.map((row) => {
        return row.filter((_, index) => filteredIndexes.includes(index))
      })

    const rowHeight = parseInt(getCssVar('--height-tr').slice(0, -2))

    return (
      <Grid
        className={'caseSummaryFinancials'}
        data={visibleData}
        columns={visibleFields.map((field) => ({ ...field, width: 128 }))}
        rowHeaders={false}
        height={additionalPanel ? '100%' : (data.length + 1) * (rowHeight + 1) + 4}
        stretchH="all"
        width={additionalPanel
          ? Math.max(Math.min(visibleFields.length * 128, window.innerWidth * 0.8 - 448), 352)
          : '100%'
        }
      />
    )
  }, [ financialsData, additionalPanel ])

  const summaryItems = useMemo(() => {
    return [
      ...getItems(summaryFieldIds),
      additionalPanel
        ? null
        : {
            key: 'financials',
            id: 'financials',
            title: 'Financials',
            Component: loadingFinancials ? Spinner : SummaryComponent,
            suffix: (
              <IconButton icon={FullscreenTableIcon} onClick={openAdditionalPanel} />
            ),
          },
    ].filter(Boolean)
  }, [ getItems, loadingFinancials, SummaryComponent, openAdditionalPanel, additionalPanel ])

  const inputsItems = useMemo(() => getItems(inputsFieldIds), [ getItems ])

  return (
    <div className='bc-summary'>
      <div className='bc-summary-content'>
      <Pivot selectedKey={tab} onLinkClick={handleLinkClick} className={FW_TABS_3}>
        <Pivot.Item headerText="Summary" itemKey="summary">
          <PanelSections id="bc-summary" items={summaryItems} />
          {!loadingFinancials && additionalPanel && additionalPanelRef && additionalPanelRef.current && createPortal(
            <SummaryComponent />,
            additionalPanelRef.current,
          )}
        </Pivot.Item>
        <Pivot.Item headerText="Inputs" itemKey="inputs">
          <PanelSections id="bc-inputs" items={inputsItems} />
        </Pivot.Item>
        <Pivot.Item headerText="Differences" itemKey="differences">
          {!loading && differencesData?.data?.length > 0
            ? <Grid
                columns={differencesData.fields}
                data={differencesData.data}
                rowHeaders={false}
                manualColumnMove={false}
                stretchH={'all'}
              />
            : DifferencesMessage}
        </Pivot.Item>
        {/* <Pivot.Item headerText="Financials" itemKey="financials">
          {!loadingFinancials
            ? <Grid
                data={financialsData.data}
                columns={financialsData.fields}
                rowHeaders={false}
                stretchH={'all'}
              />
            : null}
        </Pivot.Item> */}
      </Pivot>
      </div>
      <div className='bc-summary-footer'>
        <SecondaryButton onClick={handleOnClose} text="Close" />
        <PrimaryButton onClick={handleOnSave} disabled={!isModified} text="Save" />
      </div>
    </div>
  )
}

export default BCSummaryPanel
