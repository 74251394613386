import * as Constants from '../../features/bc/constants'

export const summaryFieldIds = {
  Summary: [
    Constants.BC_PROJECT_NAME,
    Constants.BC_PROJECT_ADDRESS,
    Constants.BC_PROJECT_LEAD,
    Constants.BC_PROJECT_SPONSOR,
    Constants.BC_FPA_REPRESENTATIVE,
  ],
  'Valuation & KPIs': [
    Constants.BC_VALUATION_KPI_NPV,
    Constants.BC_VALUATION_KPI_ROI,
    Constants.BC_VALUATION_KPI_IRR,
    Constants.BC_VALUATION_KPI_PPY,
    Constants.BC_VALUATION_KPI_WAC,
  ],
}

export const inputsFieldIds = {
  General: [
    Constants.BC_NAME_FIELD,
    Constants.BC_SITE_FIELD,
    Constants.BC_CALC_METHOD_FIELD,
    Constants.BC_EDRPOU_FIELD,
    Constants.BC_ASSOCIATION_INCOME_FIELD,
    Constants.BC_CASE_DURATION_FIELD,
    Constants.BC_CASE_TYPE_FIELD,
    Constants.BC_SITE_TYPE_FIELD,
    Constants.BC_DATE_CAPEX_FIELD,
    Constants.BC_DATE_REVENUE_FIELD,
  ],
  CAPEX: [
    Constants.BC_CAPEX_RAN_FIELD,
    Constants.BC_CAPEX_CONSTRUCTION_FIELD,
    Constants.BC_CAPEX_OTHER_FIELD,
  ],
  OPEX: [
    Constants.BC_OPEX_MAINTENANCE_FIELD,
    Constants.BC_OPEX_RENT_FIELD,
    Constants.BC_OPEX_UTILITIES_FIELD,
    Constants.BC_OTHER_OPEX_FIELD,
  ],
  Other: [
    Constants.BC_ADD_REVENUE_FIELD,
    Constants.BC_REVENUE_TREND_FIELD,
    Constants.BC_TOWERCO_FIELD,
    Constants.BC_TOWER_CO_RENT_FIELD,
    Constants.BC_DATA_CANN_COEF_FIELD,
    Constants.BC_VOICE_CANN_COEF_TRAF_FIELD,
    Constants.BC_VOICE_CANN_COEF_REF_FIELD,
    Constants.BC_COMMENTS_FIELD,
    Constants.BC_STATUS_COMMENT_FIELD,
    Constants.BC_STATUS_NAME_FIELD,
    Constants.BC_APPROVED_BY,
    Constants.BC_APPROVED_AT,
  ],
}

// Rename Case Summary fields in a specific tab and status
export const bcSummaryFieldsByTab = {
  summary: {
    [Constants.BC_PROJECT_NAME]: { label: 'BC Name' },
    [Constants.BC_PROJECT_ADDRESS]: { label: 'Address' },
    [Constants.BC_PROJECT_LEAD]: { label: 'Project Lead' },
    [Constants.BC_FPA_REPRESENTATIVE]: { label: 'FP&A Representative' },
  },
  other: {
    [Constants.BC_STATUS_ID.REJECTED]: {
      [Constants.BC_APPROVED_BY]: { label: 'Rejected By' },
      [Constants.BC_APPROVED_AT]: { label: 'Rejected Date', type: 'datetime' },
    },
  },
}
