import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner, SpinnerSize } from '@fluentui/react'
import Handsontable from 'handsontable'
import {
  loadMacroIndicators, selectLoading, selectMacroIndicators,
  selectSaving, saveMacroIndicators,
  selectUpdatedYears, addUpdatedYears,
} from '../../features/macroIndicators/macroIndicatorsSlice'
import Grid from '../../components/common/EditableGrid'
// import SaveCommandBar from '../../components/common/SaveCommandBar'
import { saveEventLog, wrapByEventLog, EVENT_TYPE } from '../../features/eventLog/eventLogSlice'
import HeaderContext from '../../layout/context/HeaderContext'

const INDICATORS = [
  { id: 'indexes-financial', label: 'Financial indexes', header: true },
  { id: 'indexes-financial.wacc', label: 'WACC', percentage: true },
  { id: 'indexes-financial.cpt', label: 'CPT', percentage: true },
  { id: 'indexes-financial.customer-retention-cost', label: 'Customer retention cost', percentage: true },
  { id: 'indexes-financial.other-direct-cost', label: 'Other direct cost', percentage: true },
  { id: 'indexes-financial.commission-for-top-ups', label: 'Commission for top-ups', percentage: true },
  { id: 'indexes-financial.tower-co-effect', label: 'TowerCo effect' },
  {
    id: 'indexes-financial.monthly-maintenance-without-tower-co',
    label: 'Monthly maintenance, UAH (without TowerCo)',
    monthlyMaintenance: true,
  },
  {
    id: 'indexes-financial.monthly-maintenance-with-tower-co',
    label: 'Monthly maintenance, UAH (with TowerCo)',
    monthlyMaintenance: true,
  },
  { id: 'trends-price', label: 'Price trends', header: true },
  { id: 'trends-price.appm', label: 'APPM trend, %', percentage: true },
  { id: 'trends-price.appgb', label: 'APPGB trend, %', percentage: true },
  { id: 'trends-price.other-mobile-revenue', label: 'Other mobile revenue trend, %', percentage: true },
  { id: 'trends-price.messaging-revenue', label: 'Messaging revenue trend, %', percentage: true },
  { id: 'trends-price.itc-revenue', label: 'ITC revenue trend, %', percentage: true },
  { id: 'trends-price.itc-cost', label: 'ITC cost trend, %', percentage: true },
  { id: 'trends-price.b2b-revenue', label: 'B2B revenue %', percentage: true },
  { id: 'indexes-other', label: 'Other indexes', header: true },
  { id: 'indexes-other.data-traffic-trend', label: 'Data traffic trend, %', percentage: true },
  { id: 'indexes-other.voice-traffic-trend', label: 'Voice traffic trend, %', percentage: true },
  { id: 'indexes-other.rent-cpi', label: 'Rent (CPI)', percentage: true },
  { id: 'indexes-other.electricity-tariff', label: 'Electricity (tariff)', percentage: true },
  { id: 'indexes-other.data-cannibalization-coef', label: 'Data Cannibalization coef.' },
  {
    id: 'indexes-other.voice-cannibalization-coef-traffic',
    label: 'Voice Cannibalization coef. – traf',
  },
  {
    id: 'indexes-other.voice-cannibalization-coef-revenue',
    label: 'Voice Cannibalization coef. – rev ',
  },
  { id: 'capex', label: 'CAPEX (UCRF)', header: true },
  { id: 'capex.rbs-gsm-900', label: 'RBS GSM-900' },
  { id: 'capex.rbs-gsm-1800', label: 'RBS GSM-1800' },
  { id: 'capex.rbs-umts-2100', label: 'RBS UMTS-2100' },
  { id: 'capex.rbs-lte-900', label: 'RBS LTE-900' },
  { id: 'capex.rbs-lte-1800', label: 'RBS LTE-1800' },
  { id: 'capex.rbs-lte-2100', label: 'RBS LTE-2100' },
  { id: 'capex.rbs-lte-2300', label: 'RBS LTE-2300' },
  { id: 'capex.rbs-lte-2600', label: 'RBS LTE-2600' },
  { id: 'opex', label: 'OPEX (UCRF)', header: true },
  { id: 'opex.rbs-gsm-900', label: 'RBS GSM-900' },
  { id: 'opex.rbs-gsm-1800', label: 'RBS GSM-1800' },
  { id: 'opex.rbs-umts-2100', label: 'RBS UMTS-2100' },
  { id: 'opex.rbs-lte-900', label: 'RBS LTE-900' },
  { id: 'opex.rbs-lte-1800', label: 'RBS LTE-1800' },
  { id: 'opex.rbs-lte-2100', label: 'RBS LTE-2100' },
  { id: 'opex.rbs-lte-2300', label: 'RBS LTE-2300' },
  { id: 'opex.rbs-lte-2600', label: 'RBS LTE-2600' },
  { id: 'capex-depreciation', label: 'CAPEX (Years of depreciation)', header: true },
  { id: 'capex-depreciation.ran', label: 'Capex RAN' },
  { id: 'capex-depreciation.construction', label: 'Capex Construction' },
  { id: 'capex-depreciation.other', label: 'Capex Other, incl. UCRF' },
  { id: 'capex-depreciation.site', label: 'Capex Site' },
]

const mmRenderer = function (instance, td, row, col, prop, value, cellProperties) {
  let renderer
  try {
    renderer = Handsontable.renderers.getRenderer('numeric')
  } catch (e) {
    renderer = Handsontable.renderers.NumericRenderer
  }
  renderer.apply(this, arguments)
  if (value < 0) {
    td.style.color = 'red'
  }
}

const boldRenderer = function (instance, td, row, col, prop, value, cellProperties) {
  Handsontable.renderers.TextRenderer.apply(this, arguments)
  td.style.fontWeight = 'bold'
}

const percentageRenderer = function (instance, td, row, col, prop, value, cellProperties) {
  Handsontable.renderers.NumericRenderer.apply(this, arguments)
  td.innerHTML = value ? `${value} %` : ''
}

const MacroIndicatorsComponent = ({ setMacroIndicatorActions }) => {
  const dispatch = useDispatch()
  const gridRef = useRef()

  const loading = useSelector(selectLoading)
  const saving = useSelector(selectSaving)
  const macroIndicators = useSelector(selectMacroIndicators)
  const updatedYears = useSelector(selectUpdatedYears)

  const [ data, setData ] = useState(null)
  const [ columns, setColumns ] = useState([])

  const years = useMemo(() => Object.keys(macroIndicators), [ macroIndicators ])

  useEffect(() => {
    dispatch(addUpdatedYears(null))
    dispatch(loadMacroIndicators())
  }, [ dispatch ])

  const saveData = useCallback(() => {
    if (!gridRef.current || saving || !updatedYears) {
      return
    }
    return dispatch(wrapByEventLog({
      type: EVENT_TYPE.macroSave,
      action: async () => {
        const res = await dispatch(saveMacroIndicators(updatedYears))
        if (res) {
          await dispatch(loadMacroIndicators())
        }
      },
    }))
  }, [ dispatch, saving, updatedYears ])

  useEffect(() => {
    if (!macroIndicators || years.length === 0) {
      return
    }

    dispatch(saveEventLog(EVENT_TYPE.macroActions, `: Preparing data for ${years.length} year(s)`))

    setColumns([ { id: 'id', label: '&nbsp;' }, { id: 'MacroIndex', label: 'Macro index' } ].concat(
      years.map((year) => ({
        id: year, label: year, type: 'numeric', editable: true,
      })),
    ))

    const data = []
    let counter = 0
    INDICATORS.forEach((indicator, index) => {
      const { id, label, header } = indicator
      if (!data[index]) {
        data[index] = [ header ? null : ++counter, label ]
      }
      if (!header) {
        const indicators = id.split('.')
        const group = indicators[0]
        const name = indicators[1]
        years.forEach((year) => {
          const groupItem = macroIndicators[year][group]
          data[index].push(groupItem ? groupItem[name] : 0)
        })
      }
    })
    setData(data)
  }, [ macroIndicators, years, dispatch ])

  const onChange = (e) => {
    const modifiedColumns = { ...updatedYears } || {}
    let changed = false
    e.forEach((cell) => {
      const [ row, column, before, after ] = cell
      const year = years[column - 2]
      if (before !== after) {
        if (!modifiedColumns[year]) {
          modifiedColumns[year] = {}
        }
        const indicator = INDICATORS[row].id
        const [ section, indicatorId ] = indicator.split('.')
        try {
          // remove preventExtensions
          modifiedColumns[year] = {
            ...modifiedColumns[year],
          }
          if (indicatorId) {
            if (!modifiedColumns[year][section]) {
              modifiedColumns[year][section] = {}
            }
            modifiedColumns[year][section] = {
              ...modifiedColumns[year][section],
            }
            modifiedColumns[year][section][indicatorId] = after
          } else {
            modifiedColumns[year][section] = after
          }
          changed = true
        } catch (e) {
          console.error(e)
        }
      }
    })
    if (changed) {
      dispatch(addUpdatedYears(modifiedColumns))
    }
  }

  const beforePaste = function (data, coords) {
    dispatch(saveEventLog(EVENT_TYPE.macroActions, `: Update data for paste action. Coordinates: ${JSON.stringify(coords)}`))
    data.forEach((row, rowIndex) => {
      row.forEach((item, colIndex) => {
        const value = item.replace(/[%\t ]/g, '').trim()
        data[rowIndex][colIndex] = value === '' ? null : value
      })
    })
  }

  const colWidths = function (index) {
    return (index === 0) ? 30 : ((index === 1) ? 270 : 80)
  }

  const mergeCells = INDICATORS.map((indicator, row) => {
    if (indicator.header) {
      return { row, col: 1, rowspan: 1, colspan: 12 }
    }
    return null
  }).filter(Boolean)

  const cells = (row, col) => {
    const cellProperties = {}
    const { header, monthlyMaintenance, percentage } = INDICATORS[row]
    if (col > 1 && monthlyMaintenance) {
      cellProperties.renderer = mmRenderer
    } else if (col > 1 && percentage) {
      cellProperties.renderer = percentageRenderer
    } else if (col === 1 && header) {
      cellProperties.renderer = boldRenderer
    }
    return cellProperties
  }

  const macroIndicatorActions = useMemo(() => ({
    save: saveData,
    saveDisabled: saving || !updatedYears,
  }), [ saveData, saving, updatedYears ])

  const initialCell = useMemo(() => ({ row: 1, col: 2 }), [])

  useEffect(() => {
    setMacroIndicatorActions && setMacroIndicatorActions(macroIndicatorActions)
  }, [ macroIndicatorActions, setMacroIndicatorActions ])

  return (
    loading || !data
      ? (
          <Spinner className="centered" size={SpinnerSize.large} />
        )
      : (
          <div style={{ height: 'calc(100% - 44px)' }}>
            <Grid
              refHot={gridRef}
              data={data}
              columns={columns}
              onChange={onChange}
              beforePaste={beforePaste}
              colWidths={colWidths}
              mergeCells={mergeCells}
              columnSorting={false}
              initialCell={initialCell}
              {...{
                cells,
              }}
            />
          </div>
        )
  )
}

const MacroIndicators = () => (
  <HeaderContext.Consumer>
    {({ setMacroIndicatorActions }) => (
      <MacroIndicatorsComponent setMacroIndicatorActions={setMacroIndicatorActions} />
    )}
  </HeaderContext.Consumer>
)

export default MacroIndicators
