import { hiddenCells, hiddenHeader, rendererItem } from '../common/utils/hotTable'
import {
  ApproveIcon,
  BusinessCasesIcon,
  CalculateDraftIcon,
  CalculatePainZonesIcon,
  CaseSummaryIcon,
  CheckmarkIcon,
  CopyIcon,
  CutIcon,
  DeleteIcon,
  ExceptSelectedIcon,
  FilterIcon,
  // FreezeColumnIcon,
  // FreezeRowIcon,
  HideIcon,
  MinusIcon,
  // MoreActionsIcon,
  // MoveDownIcon,
  MoveLeftIcon,
  MoveRightIcon,
  // MoveUpIcon,
  OnlySelectedIcon,
  OpenSiteIcon,
  PasteIcon,
  PlusIcon,
  RejectIcon,
  ReplaceIcon,
  SortDownIcon,
  SortIcon,
  SortUpIcon,
} from '../common/icons/names'
import { DATA_TYPES } from '../../constants/common'
import { alwaysTrue } from '../../utils/format'

const contextMenu = {}

const headerMenu = (disabled) => ({
  items: {
    // header:
    moveLeft: {
      hidden: hiddenHeader,
      disabled: disabled?.moveLeft,
      renderer: rendererItem('Move Left', MoveLeftIcon),
      name: 'moveLeft',
    },
    moveRight: {
      hidden: hiddenHeader,
      disabled: disabled?.moveRight,
      renderer: rendererItem('Move Right', MoveRightIcon),
      name: 'moveRight',
    },
    hideColumn: {
      hidden: hiddenHeader,
      disabled: disabled?.hideColumn,
      renderer: rendererItem('Hide Column', HideIcon),
      name: 'hideColumn',
    },
    /* freezeColumn: {
      hidden: hiddenHeader,
      disabled: true,
      renderer: rendererItem('Freeze Column', FreezeColumnIcon),
      name: 'freezeColumn',
    }, */
    sp1: '---------',
    sortAscending: {
      hidden: hiddenHeader,
      disabled: disabled?.sortAscending,
      renderer: rendererItem('Sort Ascending', SortUpIcon),
      name: 'sortAscending',
    },
    sortDescending: {
      hidden: hiddenHeader,
      disabled: disabled?.sortDescending,
      renderer: rendererItem('Sort Descending', SortDownIcon),
      name: 'sortDescending',
    },
  },
})

const filterMenu = (disabled) => ({
  items: {
    quickFilters: {
      hidden: hiddenCells,
      renderer: rendererItem('Quick Filters', FilterIcon),
      name: 'quickFilters',
      submenu: {
        items: [
          {
            key: 'quickFilters:onlySelected',
            name: 'onlySelected',
            renderer: rendererItem('Only Selected', OnlySelectedIcon),
          },
          {
            key: 'quickFilters:exceptSelected',
            name: 'exceptSelected',
            renderer: rendererItem('Except Selected', ExceptSelectedIcon),
          },
        ],
      },
    },
    sort: {
      hidden: hiddenCells,
      renderer: rendererItem('Sort', SortIcon),
      name: 'sort',
      disabled: disabled?.sortAscending && disabled?.sortDescending,
      submenu: {
        items: [
          {
            key: 'sort:ascending',
            name: 'ascending',
            disabled: disabled?.sortAscending,
            renderer: rendererItem('Ascending', SortUpIcon),
          },
          {
            key: 'sort:descending',
            name: 'descending',
            disabled: disabled?.sortDescending,
            renderer: rendererItem('Descending', SortDownIcon),
          },
        ],
      },
    },
  },
})

contextMenu[DATA_TYPES.SITES] = (disabled, hiddenCustom) => {
  const hidden = (name) => function () {
    if (this.getSelectedLast()[0] === -1) {
      return true
    } else {
      return hiddenCustom && hiddenCustom(name, this.getSelected())
    }
  }
  return {
    items: {
      // cells
      createBC: {
        hidden: hidden('createBC'),
        disabled: false,
        renderer: rendererItem('Create BC', BusinessCasesIcon),
        name: 'createBC',
      },
      open: {
        hidden: hiddenCells,
        disabled: disabled?.open,
        renderer: rendererItem('Open', OpenSiteIcon),
        name: 'open',
      },
      sp2: '---------',
      cut: {
        hidden: hiddenCells,
        renderer: rendererItem('Cut', CutIcon),
        name: 'cut',
        disabled: disabled?.cut,
      },
      copy: {
        hidden: hiddenCells,
        renderer: rendererItem('Copy', CopyIcon),
        name: 'copy',
      },
      paste: {
        hidden: hiddenCells,
        renderer: rendererItem('Paste', PasteIcon),
        name: 'paste',
        disabled: disabled?.paste,
      },
      sp3: '---------',
      addRow: {
        hidden: hiddenCells,
        renderer: rendererItem('Add Row', PlusIcon),
        name: 'addRow',
        disabled: disabled?.addRow,
      },
      deleteRow: {
        hidden: hiddenCells,
        renderer: rendererItem('Delete Row', DeleteIcon),
        name: 'deleteRow',
        disabled: disabled?.deleteRow,
      },
    /* moveActions: {
      hidden: hiddenCells,
      renderer: rendererItem('More Actions', MoreActionsIcon),
      name: 'moveActions',
      submenu: {
        items: [
          {
            key: 'moveActions:moveUp',
            name: 'moveUp',
            disabled: true,
            renderer: rendererItem('Move Up', MoveUpIcon),
          },
          {
            key: 'moveActions:moveDown',
            name: 'moveDown',
            disabled: true,
            renderer: rendererItem('Move Down', MoveDownIcon),
          },
          {
            key: 'moveActions:freezeRow',
            name: 'freezeRow',
            disabled: true,
            renderer: rendererItem('Freeze Row', FreezeRowIcon),
          },
        ],
      },
    }, */
    },
  }
}

contextMenu[DATA_TYPES.SECTORS] = (disabled, hidden) => {
  return contextMenu[DATA_TYPES.SITES](disabled, hidden)
}
contextMenu[DATA_TYPES.BASE_STATIONS] = (disabled, hidden) => {
  const hiddenCustom = (name, ...others) => {
    if (name === 'createBC') {
      return true
    }
    return hidden(name, ...others)
  }
  return contextMenu[DATA_TYPES.SITES](
    { ...disabled, paste: true, cut: true, addRow: true, deleteRow: true },
    hiddenCustom,
  )
}
contextMenu[DATA_TYPES.BUSINESS_CASES] = (disabled, hiddenCustom) => {
  const hidden = (name) => function () {
    if (this.getSelectedLast()[0] === -1) {
      return true
    } else {
      return hiddenCustom(name)
    }
  }
  return {
    items: {
      openGroup: {
        hidden: hidden('openGroup'),
        renderer: rendererItem('Open Business Case', OpenSiteIcon),
        name: 'openGroup',
        disabled: disabled?.openGroup,
      },
      sp2: '---------',
      copy: {
        hidden: hiddenCells,
        renderer: rendererItem('Copy', CopyIcon),
        name: 'copy',
      },
      sp5: '---------',
      copyGroup: {
        hidden: hidden('copyGroup'),
        renderer: rendererItem('Copy Group', CopyIcon),
        name: 'copyGroup',
        disabled: disabled?.copyGroup,
      },
      caseSummary: {
        hidden: hidden('caseSummary'),
        renderer: rendererItem('Case Summary', CaseSummaryIcon),
        name: 'caseSummary',
        disabled: disabled?.caseSummary,
      },
      sp3: '---------',
      deleteBC: {
        hidden: hidden('deleteBC'),
        renderer: rendererItem('Delete', DeleteIcon),
        name: 'deleteBC',
        disabled: disabled?.deleteBC,
      },
      approveBC: {
        hidden: hidden('approveBC'),
        renderer: rendererItem('Approve', ApproveIcon),
        name: 'approveBC',
        disabled: disabled?.approveBC,
      },
      rejectBC: {
        hidden: hidden('rejectBC'),
        renderer: rendererItem('Reject', RejectIcon),
        name: 'rejectBC',
        disabled: disabled?.rejectBC,
      },
      sp4: '---------',
    },
  }
}

contextMenu[DATA_TYPES.CREATE_BUSINESS_CASES] = (disabled, hiddenCustom, busyColumns) => {
  const hidden = (name) => function () {
    if (this.getSelectedLast()[0] === -1) {
      return true
    } else {
      return hiddenCustom(name)
    }
  }
  return {
    items: {
      calculateDraft: {
        hidden: hidden('calculateDraft'),
        renderer: rendererItem('Calculate Draft', CalculateDraftIcon),
        label: 'Calculate Draft',
        icon: CalculateDraftIcon,
        name: 'calculateDraft',
        disabled: disabled?.calculateDraft,
      },
      recalculateDraft: {
        hidden: hidden('recalculateDraft'),
        renderer: rendererItem('Recalculate Draft', CalculateDraftIcon),
        label: 'Recalculate Draft',
        icon: CalculateDraftIcon,
        name: 'recalculateDraft',
        disabled: disabled?.recalculateDraft,
      },
      calculateForApproval: {
        hidden: hiddenCells,
        renderer: rendererItem('Calculate for Approval', CalculatePainZonesIcon),
        name: 'calculateForApproval',
        label: 'Calculate for Approval',
        icon: CalculatePainZonesIcon,
        disabled: disabled?.calculateForApproval,
      },
      sendForApproval: {
        hidden: hiddenCells,
        renderer: rendererItem('Send for Approval', ApproveIcon),
        label: 'Send for Approval',
        icon: ApproveIcon,
        name: 'sendForApproval',
        disabled: disabled?.sendForApproval,
      },
      cancelCalculation: {
        hidden: hiddenCells,
        renderer: rendererItem('Cancel Calculation', RejectIcon),
        label: 'Cancel Calculation',
        icon: RejectIcon,
        name: 'cancelCalculation',
        disabled: disabled?.cancelCalculation,
      },
      createBC: {
        hidden: hiddenCells,
        renderer: rendererItem('Create BC', PlusIcon),
        label: 'Create BC',
        icon: PlusIcon,
        name: 'createBC',
        disabled: disabled?.createBC,
      },
      selectBC: {
        hidden: hiddenCells,
        renderer: rendererItem('Select Existing BC', BusinessCasesIcon),
        label: 'Select Existing BC',
        icon: BusinessCasesIcon,
        name: 'selectBC',
        disabled: disabled?.selectBC,
      },
      deleteGroupBC: {
        hidden: hiddenCells,
        renderer: rendererItem('Delete Group BC', DeleteIcon),
        name: 'deleteGroupBC',
        label: 'Delete Group BC',
        icon: DeleteIcon,
        disabled: disabled?.deleteGroupBC,
      },
      deleteBC: {
        hidden: hiddenCells,
        renderer: rendererItem('Delete Selected BC', DeleteIcon),
        name: 'deleteBC',
        label: 'Delete Selected BC',
        icon: DeleteIcon,
        disabled: disabled?.deleteBC,
      },
      copy: {
        hidden: hiddenCells,
        label: 'Copy',
        name: 'copy',
        icon: CopyIcon,
      },
      refresh: {
        hidden: hidden('refresh'),
        label: 'Refresh',
        name: 'refresh',
        icon: ReplaceIcon,
      },
    },
  }
}

contextMenu[DATA_TYPES.TRF] = (disabled) => {
  return {
    items: {
      addRow: {
        hidden: hiddenCells,
        renderer: rendererItem('Add Row', PlusIcon),
        name: 'addRow',
        disabled: disabled?.addRow,
      },
      deleteLastRow: {
        hidden: hiddenCells,
        renderer: rendererItem('Remove Last Row', MinusIcon),
        name: 'deleteLastRow',
        disabled: disabled?.deleteLastRow,
      },
    },
  }
}

contextMenu[DATA_TYPES.NOTIFICATIONS] = (disabled) => {
  return {
    items: {
      setRead: {
        hidden: hiddenCells,
        renderer: rendererItem('Mark as read', CheckmarkIcon),
        name: 'setRead',
        disabled: disabled?.setRead,
      },
      setUnread: {
        hidden: hiddenCells,
        renderer: rendererItem('Mark as unread', MinusIcon),
        name: 'setUnread',
        disabled: disabled?.setUnread,
      },
      deleteItems: {
        hidden: hiddenCells,
        renderer: rendererItem('Remove', DeleteIcon),
        name: 'deleteItems',
        disabled: disabled?.deleteItems,
      },
    },
  }
}

contextMenu[DATA_TYPES.COMPLAINTS] = (disabled) => {
  return {
  }
}

export const DO_NOT_SHOW_HEADER = [ DATA_TYPES.NOTIFICATIONS, DATA_TYPES.TRF ]
export const DO_NOT_SHOW_FILTER = [ DATA_TYPES.CREATE_BUSINESS_CASES, DATA_TYPES.TRF, DATA_TYPES.NOTIFICATIONS ]

export const existsContextMenu = (dataType) => !!contextMenu[dataType]

export const genContextMenu = (dataType, disabled, hidden, busy) => {
  return contextMenu[dataType] && {
    items: {
      ...(DO_NOT_SHOW_HEADER.includes(dataType) ? [] : headerMenu(disabled).items),
      ...contextMenu[dataType](disabled, hidden, busy).items,
      ...(DO_NOT_SHOW_FILTER.includes(dataType) ? [] : filterMenu(disabled).items),
      no_items: {
        hidden: alwaysTrue,
      },
    },
  }
}
