import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  FontWeights, getTheme, IconButton, mergeStyleSets, Modal, Stack, StackItem, Label,
  Spinner,
  SpinnerSize,
  Checkbox,
} from '@fluentui/react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { DefaultButton, PrimaryButton } from '../common/Button'

import './SelectPeriod.css'
import DatePicker from '../common/DatePicker'
import { DATE_FORMAT } from '../Tables/utils'
import { getProjectProperties, selectIsLoading, selectProjectProperties } from '../../features/reports/reportsSlice'

const theme = getTheme()

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}

const cancelIcon = { iconName: 'Cancel' }

const contentStyles = mergeStyleSets({
  header: [
    theme.fonts.xxLarge,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
})

const currentDate = new Date()
const minDate = new Date(2020, 0, 1)

const SelectPeriod = ({ onCancel, onSubmit }) => {
  const dispatch = useDispatch()

  const [ fromDate, setFromDate ] = useState(currentDate)
  const [ toDate, setToDate ] = useState(currentDate)
  const [ currentPeriod, setCurrentPeriod ] = useState(null)
  const [ includeMaps, setIncludeMaps ] = useState(false)

  const isLoading = useSelector(selectIsLoading)
  const projectProperties = useSelector(selectProjectProperties)

  const isSelectFileDisabled = useMemo(() => {
    return !fromDate || !toDate || moment(fromDate).isAfter(moment(toDate))
  }, [ fromDate, toDate ])

  useEffect(() => {
    dispatch(getProjectProperties())
  }, [ dispatch ])

  useEffect(() => {
    if (projectProperties && projectProperties['sync-period']) {
      const month = moment(projectProperties['sync-period']).format('MMMM YYYY')
      setCurrentPeriod(month)
    }
  }, [ projectProperties ])

  const handleOnOk = useCallback(() => {
    onSubmit({
      fromDate: moment(fromDate).format(DATE_FORMAT),
      toDate: moment(toDate).format(DATE_FORMAT),
      includeMaps,
    })
  }, [ onSubmit, fromDate, toDate, includeMaps ])

  const handleIncludeMaps = useCallback((_, value) => {
    setIncludeMaps(value)
  }, [ setIncludeMaps ])

  return (
    <Modal
      width={520}
      containerClassName="top-most"
      isOpen={true}
      isBlocking={true}
    >
      <div className={contentStyles.header}>
        <span>{'Select Period'}</span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          onClick={onCancel}
        />
      </div>
      <Stack className="modal-content-select-period">
        <StackItem className='current-period'>
          <Label>{'Current period:'}</Label>
          {isLoading
            ? <Spinner size={SpinnerSize.xSmall} />
            : currentPeriod ?? 'Not available'
          }
        </StackItem>
        <StackItem>
          <DatePicker
            label="From date"
            placeholder="Select a date..."
            value={fromDate}
            onSelectDate={setFromDate}
            minDate={minDate}
            maxDate={toDate || currentDate}
          />
        </StackItem>
        <StackItem>
          <DatePicker
            label="To date"
            placeholder="Select a date..."
            showGoToToday={true}
            value={toDate}
            onSelectDate={setToDate}
            minDate={fromDate || minDate}
            maxDate={currentDate}
          />
        </StackItem>
        <Checkbox
            label={'Include Maps'}
            checked={includeMaps}
            onChange={handleIncludeMaps}
          />
        <StackItem className='financials-buttons'>
          <DefaultButton className="dialog-button-select-period" text="Cancel" onClick={onCancel} />
          <PrimaryButton
            className="dialog-button-select-period"
            text="Select File"
            onClick={handleOnOk}
            disabled={isSelectFileDisabled}
          />
        </StackItem>
      </Stack>
    </Modal>
  )
}

export default SelectPeriod
